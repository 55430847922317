// eslint-disable-next-line import/prefer-default-export
export const confirm = {
  methods: {
    /**
     * Metoda zmieniająca stan (store) okna potwierdzenia (Confirm)
     * @param {Function} callback Funkcja wywoływana po zatwierdzeniu przciskiem OK
     * @param {string} message Opcjonalny parametr - tytuł okna Confirm
     */
    confirm(callback, message) {
      this.$store.dispatch('confirmation/openDialog', { callback, message });
    },
  },
};
