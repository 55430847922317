<template>
  <Transition name="fade">
    <div v-if="activeModal && !closing" @click.self="bgClose ? closeModal() : null" class="fixed py-16 inset-0 w-full h-screen flex items-center justify-center bg__semi z-50">
      <div
        class="relative flex flex-col min-h-20 max-h-full shadow-lg"
        :class="{'xl:w-3/5 lg:w-2/3 md:w-5/6 w-11/12': size === 'xl', 'xl:w-2/5 lg:w-3/5 md:w-4/5 w-11/12': size === 'lg', 'xl:w-2/6 lg:w-1/2, md:w-2/3 w-10/12': size === 'md'}"
      >
        <button @click="closeModal" aria-label="close" class="absolute top-0 right-0 text-xl text-gray-600 hover:text-gray-900 my-1 mx-3 outline-none">
          <font-awesome-icon icon="times"/>
        </button>
        <div class="inset-x-0 top-0 text-xl font-semibold bg-gray-300 px-4 py-1 rounded-t-lg">
          <slot name="header" />
        </div>
        <div class="relative max-h-full overflow-y-auto p-6 bg-gray-100">
          <slot />
        </div>
        <div class="inset-x-0 bottom-0 bg-gray-100 px-4 py-1 rounded-b-lg text-right shadow-inner">
          <slot name="footer">
            <button v-if="cancel" class="my-1 px-3 py-1 bg-gray-600 text-gray-200 hover:bg-gray-700 font-semibold tracking-wide rounded" @click="closeModal">{{ cancelLabel }}</button>
            <button v-if="close" class="my-1 px-3 py-1 bg-gray-500 text-white hover:bg-gray-600 font-semibold tracking-wide rounded" @click="closeModal">zamknij</button>
            <button v-if="ok" class="my-1 px-3 py-1 bg-blue-500 text-gray-200 hover:bg-blue-700 font-semibold tracking-wide rounded ml-1" @click="$emit('ok')">{{ okLabel }}</button>
          </slot>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    activeModal: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'lg',
    },
    bgClose: {
      type: Boolean,
      default: true,
    },
    cancel: {
      type: Boolean,
      default: true,
    },
    cancelLabel: {
      type: String,
      default: 'anuluj',
    },
    ok: {
      type: Boolean,
      default: true,
    },
    okLabel: {
      type: String,
      default: 'ok',
    },
    close: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      closing: false,
    };
  },
  methods: {
    closeModal() {
      this.closing = true;
      setTimeout(() => {
        this.$emit('closeModal');
        this.closing = false;
      }, 400);
    },
  },
  watch: {
    activeModal(newVal) {
      if (!newVal) {
        this.closeModal();
      }
    },
    // showing(value) {
    //   if (value) {
    //     return document.querySelector('body').classList.add('overflow-hidden');
    //   }
    //   return document.querySelector('body').classList.remove('overflow-hidden');
    // },
  },
};
</script>
