import axios from 'axios';

/* eslint-disable no-shadow */
const state = {
  regions: [],
};

const mutations = {
  SET(state, regions) {
    state.regions = regions;
  },
};

const actions = {
  get({ commit }) {
    axios.get(`${process.env.VUE_APP_API_URL}/regions`)
      .then(response => commit('SET', response.data))
      .catch(error => console.log(error));
  },
};

const getters = {
  regions() {
    return state.regions;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
