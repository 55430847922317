<template>
  <Transition name="fade">
    <div v-if="activeModal && !closing" class="fixed py-16 inset-0 w-full h-screen flex items-center justify-center bg__semi z-50">
      <div
        class="relative flex flex-col min-h-20 max-h-full shadow-lg xl:w-2/6 lg:w-1/2 md:w-2/3 w-10/12"
      >
        <div class="relative max-h-full overflow-y-auto p-6 bg-gray-100 rounded-lg">
          <div v-show="!done" class="flex">
            <font-awesome-icon class="mr-3" icon="spinner" size="2x" spin />
            <div>
              <p class="leading-5">Przygotowuję paczkę ZIP, nie zamykaj okna przeglądarki aż do zakończenia procesu...</p>
              <br>
              <p class="text-sm text-gray-500 leading-4"><em>Jeśli zostanie przekroczony czas odpowiedzi serwera, spróbuj pobrać dokumantację w częściach.</em></p>
            </div>
          </div> <!-- working... -->
          <div v-show="done">
            <p>Paczka została przygotowana - kliknij, aby pobrać</p>
            <a :href="zipUrl"
              @click="downloaded = true"
              class="inline-block my-2 px-3 py-1 hover:no-underline font-semibold tracking-wide rounded ml-1"
              :class="{'bg-blue-500 text-gray-200 hover:bg-blue-700 hover:text-gray-300': !downloaded, 'bg-gray-500 text-white hover:bg-gray-600 hover:text-white': downloaded}"
              target="_blank"
            >Pobierz <span v-if="downloaded">ponownie</span></a>
            <button
              @click="closeModal"
              class="my-1 px-3 py-1 font-semibold tracking-wide rounded ml-2"
              :class="{'bg-blue-500 text-gray-200 hover:bg-blue-700 font-semibold tracking-wide rounded ml-2': downloaded, 'bg-gray-500 text-white hover:bg-gray-600 hover:text-white': !downloaded}"
            >
              <span v-if="downloaded">Zamknij</span>
              <span v-else>Anuluj</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'OrderZipInfo',
  props: {
    activeModal: {
      type: Boolean,
      default: true,
    },
    zipUrl: {
      type: String,
      required: true,
    },
    done: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      closing: false,
      downloaded: false,
    };
  },
  methods: {
    closeModal() {
      this.closing = true;
      setTimeout(() => {
        this.$emit('closeModal');
        this.closing = false;
        this.downloaded = false;
      }, 400);
    },
  },
  watch: {
    activeModal(newVal) {
      if (!newVal) {
        this.closeModal();
      }
    },
    // showing(value) {
    //   if (value) {
    //     return document.querySelector('body').classList.add('overflow-hidden');
    //   }
    //   return document.querySelector('body').classList.remove('overflow-hidden');
    // },
  },
};
</script>

<style>

</style>
