<template>
  <div id="app" class="flex max-h-screen md:flex-row-reverse flex-wrap antialiased text-gray-700">

    <!-- Main content -->
    <div class="relative flex flex-col h-screen w-full" :class="contentTailView ? 'md:w-4/5 xl:w-11/12' : ''">
      <MainTopBar v-if="loggedIn" @toggleMenu="menuVisible = !menuVisible" :menuVisible="menuVisible" :userName="user.name" :breadcrumbs="breadcrumbs" />
      <router-view class="overflow-y-auto flex-grow p-6 mb-16 md:mb-0" />
    </div>

    <!-- Left Column/Bottom Row: Sidebar -->
    <div v-if="menuVisible && loggedIn" class=" fixed w-full bg-blue-900 bottom-0 h-16 md:w-1/5 md:top-0 md:left-0 md:h-screen md:border-r md:border-blue-800 xl:w-1/12">
      <MainSideBar />
    </div>
    <NotificationContainer/>
    <Confirm :show="$store.getters['confirmation/active']" />
  </div>
</template>

<script>
import MainTopBar from './components/MainTopBar.vue';
import MainSideBar from './components/MainSideBar.vue';
import NotificationContainer from './components/NotificationContainer.vue';
import Confirm from './components/Confirm.vue';

export default {
  components: {
    MainTopBar,
    MainSideBar,
    NotificationContainer,
    Confirm,
  },
  data() {
    return {
      menuVisible: true,
      contentPanel: {
        clientWidth: '',
      },
    };
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    user() {
      if (!this.loggedIn) {
        return { name: '', email: '' };
      }
      const { user } = this.$store.getters;
      return { name: user.username, email: user.email };
    },
    breadcrumbs() {
      return this.$route.meta.niceName;
    },
    contentTailView() {
      return this.menuVisible && this.loggedIn;
    },
  },
};
</script>

<style src="./assets/tailwind.css" />
