<template>
  <BaseModal
    :activeModal="show"
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    okLabel="zapisz"
    @ok="save"
    :close="false"
    :bgClose="false"
    size="md"
  >
    <template #header>
      Edycja notatki
    </template>
    <template #default>
      <p v-if="note.filename" class="text-teal-700">Możesz edytować wyłącznie pola: tytuł oraz treść. Jeśli załączony plik do notatki jest nieprawidłowy, usuń notatkę i dodaj ponownie z poprawnym plikiem</p>
      <div class="mb-4">
        <label>tytuł</label>
        <input :class="{ 'border-red-300': errors.title !== undefined }" v-model="form.title" type="text">
        <p v-if="errors.title !== undefined" class="text-red-500 text-sm">{{ errors.title }}</p>
      </div>
      <div class="mb-4">
        <label>opis dodatkowy</label>
        <textarea class="w-full px-3 py-1 border border-gray-300" rows="3" :class="{ 'border-red-300': errors.description !== undefined }" v-model="form.description" style="resize:none"></textarea>
        <p v-if="errors.description !== undefined" class="text-red-500 text-sm">{{ errors.description }}</p>
      </div>
      <div v-if="note.filename">
        <div v-if="Number(note.type_id) === 1" class="rounded mr-3">
          <img class="rounded w-16" :src="`${apiUrl}/note-image/thumb/${token}/${note.uuid}`" alt="Miniaturka">
        </div>
        <div v-else-if="Number(note.type_id) === 2" class="flex justify-center bg-yellow-600 rounded mr-3 w-16">
          <font-awesome-icon icon="file-pdf" size="4x" class="p-1" />
        </div>
      </div>

      <!-- Spinner Loading -->
      <div v-if="isBusy" class="absolute top-0 left-0 w-full h-full">
        <div class="w-full h-full bg-gray-400 opacity-75"></div>
        <div class="absolute top-0 left-0 flex w-full h-full items-center justify-center">
          <font-awesome-icon class="mr-3" icon="spinner" size="2x" spin />
          <div class="font-bold">
            <p>Zapisuję...</p>
          </div>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
// import axios from 'axios';
import NoteService from '../services/NoteService';
import { errorHandler } from '../mixins/errorHandler';

export default {
  name: 'NoteEdit',
  mixins: [errorHandler],
  props: {
    note: {
      type: Object,
      required: true,
    },
    token: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: {
        title: '',
        description: '',
        has_file: this.note.filename,
      },
      errors: {},
      show: false,
      isBusy: false,
      apiUrl: process.env.VUE_APP_API_URL,
    };
  },
  mounted() {
    this.show = true;
    this.form.title = this.note.title;
    this.form.description = this.note.description;
  },
  methods: {
    save() {
      this.isBusy = true;
      NoteService.updateNote(this.note.id, this.form)
        .then(response => {
          this.$emit('changed', response.data);
          this.isBusy = false;
          this.closeModal();
        })
        .catch(error => {
          this.errors = this.resolveError(error);
          this.isBusy = false;
        });
    },
    closeModal() {
      this.show = false;
      this.$emit('close');
    },
  },
};
</script>
