<template>
  <BaseModal
    :activeModal="show"
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    okLabel="zapisz"
    @ok="saveHandler"
    :close="false"
    :bgClose="false"
    size="md"
  >
    <template #header>
      Dodawanie notatki
    </template>
    <template #default>
      <div class="mb-4">
        <label>tytuł</label>
        <input v-focus :class="{ 'border-red-300': errors.title !== undefined }" v-model="title" type="text">
        <p v-if="errors.title !== undefined" class="text-red-500 text-sm">{{ errors.title }}</p>
      </div>
      <div class="mb-4">
        <label>opis dodatkowy</label>
        <textarea class="w-full px-3 py-1 border border-gray-300" rows="3" :class="{ 'border-red-300': errors.description !== undefined }" v-model="description" style="resize:none"></textarea>
        <p v-if="errors.description !== undefined" class="text-red-500 text-sm">{{ errors.description }}</p>
      </div>
      <div v-if="!(setSubfolder.id !== undefined && !setSubfolder.id)" class="mb-4">
        <label>
          <input type="file" :class="{'bg-red-500': fileErrors.length > 0}" multiple  @change="fileChange($event.target.files)">
          <div v-if="fileErrors.length > 0" class="px-3 text-red-500 font-normal text-sm">
            <div v-for="(errors, index) in fileErrors" :key="index">
              <p v-for="(fileErr, index) in errors" :key="index">{{ fileErr }}</p>
            </div>
          </div>
          <p v-if="errors.emptyFiles !== undefined" class="text-red-500 text-sm">{{ errors.emptyFiles }}</p>
        </label>
      </div>
      <div v-if="(filesToUpload.length > 0 || (setSubfolder.id !== undefined && setSubfolder.id)) && categoryId == 20">
        <div class="mb-4">
          <label>rodzaj załącznika/ów</label>
          <select :class="{ 'border-red-300': errors.selectedNoteSubfolder !== undefined }" @change="subfolderChanged" v-model="selectedNoteSubfolder" :disabled="setSubfolder.id !== undefined">
              <option :value="{}" disabled hidden>Wybierz</option>
              <option v-for="(subfolder, index) in noteSubfolders" :key="index" :value="subfolder">{{ subfolder.label }}</option>
          </select>
          <p v-if="errors.selectedNoteSubfolder !== undefined" class="text-red-500 text-sm">{{ errors.selectedNoteSubfolder }}</p>
        </div>
        <div v-if="selectedNoteSubfolder.typeId == 100" class="mb-4">
          <label title="Podaj cechę obiektu np. numer działki, rodzaj uprawy itp.">
            cecha obiektu <span><font-awesome-icon icon="question-circle" class="text-blue-400 cursor-pointer" /></span>
          </label>
          <input :class="{ 'border-red-300': errors.attribute !== undefined }" @input="delete errors.attribute" v-model="attribute" type="text" :disabled="selectedNoteSubfolder.id !== ''">
          <p v-if="errors.attribute !== undefined" class="text-red-500 text-sm">{{ errors.attribute }}</p>
        </div>
      </div>
      <!-- Spinner Loading -->
      <div v-if="isBusy" class="absolute top-0 left-0 w-full h-full">
        <div class="w-full h-full bg-gray-400 opacity-75"></div>
        <div class="absolute top-0 left-0 flex w-full h-full items-center justify-center">
          <font-awesome-icon class="mr-3" icon="spinner" size="2x" spin />
          <div class="font-bold">
            <p>Zapisuję dane...</p>
            <p>Nie zamykaj okna aż do zakończenia procesu...</p>
            <div v-if="filesToUpload.length > 0" class="relative h-1 w-full bg-yellow-500">
              <div class="absolute top-0 left-0 h-1 bg-blue-500" :class="uploadProgress"></div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from 'vuex';
import NoteService from '../services/NoteService';
import { errorHandler } from '../mixins/errorHandler';

export default {
  name: 'NoteAdd',
  mixins: [errorHandler],
  props: {
    categoryId: {
      type: [String, Number],
      required: true,
    },
    noteableId: {
      type: [String, Number],
      required: true,
    },
    noteableType: {
      type: String,
      required: true,
    },
    subfolders: {
      type: Array,
      default() {
        return [];
      },
    },
    setSubfolder: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      filesToUpload: [],
      title: '',
      description: '',
      selectedNoteSubfolder: {},
      attribute: '',
      notesLoadTable: [],
      errors: {},
      fileErrors: [],
      show: false,
      isBusy: false,
      listPrepared: false,
    };
  },
  computed: {
    ...mapState('notes', [
      'noteSubfolderTypeList',
    ]),
    uploadProgress() {
      let progressClass = 'w-0';
      let percent = 1;
      if (this.filesToUpload.length > 0) {
        percent = this.notesLoadTable.length / this.filesToUpload.length;
      }
      if (percent === 0) {
        progressClass = 'w-full';
      } else if (percent <= 0.2) {
        progressClass = 'w-4/5';
      } else if (percent <= 0.4) {
        progressClass = 'w-3/5';
      } else if (percent <= 0.6) {
        progressClass = 'w-2/5';
      } else if (percent <= 0.8) {
        progressClass = 'w-1/5';
      }
      return progressClass;
    },
    noteSubfolders() {
      const foldersToSelect = [];
      this.noteSubfolderTypeList.forEach(obj => {
        if (obj.id !== 100 && obj.id !== '100') {
          const existingFolder = this.subfolders.find(folder => Number(folder.type_id) === Number(obj.id));
          foldersToSelect.push({
            typeId: obj.id,
            label: obj.name,
            id: existingFolder ? existingFolder.id : '',
            typeIdNo: '',
            attribute: '',
          });
        } else {
          const existingFolders = this.subfolders.filter(folder => Number(folder.type_id) === Number(obj.id));
          if (existingFolders.length > 0) {
            let lastFolderTypeNo;
            existingFolders.forEach(folder => {
              foldersToSelect.push({
                typeId: obj.id,
                label: folder.name,
                id: folder.id,
                typeIdNo: folder.type_id_no,
                attribute: folder.attribute,
              });
              lastFolderTypeNo = Number(folder.type_id_no);
            });
            const nextFolderTypeNo = lastFolderTypeNo + 1;
            foldersToSelect.push({
              typeId: obj.id,
              label: `${obj.name} ${nextFolderTypeNo} (nowy)`,
              id: '',
              typeIdNo: nextFolderTypeNo,
              attribute: '',
            });
          } else {
            foldersToSelect.push({
              typeId: obj.id,
              label: `${obj.name} 1`,
              id: '',
              typeIdNo: 1,
              attribute: '',
            });
          }
        }
      });
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.listPrepared = true;
      return foldersToSelect;
    },
  },
  mounted() {
    this.show = true;
    if (this.noteSubfolderTypeList.length === 0) {
      this.$store.dispatch('notes/getNoteSubfolderTypeList');
    }
  },
  methods: {
    fileChange(fileList) {
      this.fileErrors = [];
      delete this.errors.emptyFiles;
      this.filesToUpload = fileList;
    },
    subfolderChanged() {
      delete this.errors.selectedNoteSubfolder;
      this.attribute = this.selectedNoteSubfolder.attribute;
    },
    saveHandler() {
      this.isBusy = true;
      let subfolderId = '';
      // walidacja obowiązkowych załączników
      if ((this.selectedNoteSubfolder.typeId !== undefined || (this.setSubfolder.id !== undefined && this.setSubfolder.id)) && this.filesToUpload.length === 0) {
        const fileErrors = { emptyFiles: 'dodajesz notatkę do folderu załączników - wybór pliku/plików obowiązkowy' };
        this.errors = fileErrors;
        this.isBusy = false;
        return false;
      }
      if ((this.categoryId === 20 || this.categoryId === '20') && this.filesToUpload.length > 0) {
        const errors = {};
        Object.assign(errors, this.errors);
        // Jeśli dodajemy do rzekomo istniejącego folderu
        if (this.setSubfolder.id !== undefined && this.setSubfolder.id) {
          // walidacja backend czy folder istnieje i czy powiązany już z noteable_type/noteable_id
          NoteService.checkSubfolderExists(this.setSubfolder.id, {
            noteable_id: this.noteableId,
            noteable_type: this.noteableType,
          })
            .then(() => {
              subfolderId = this.setSubfolder.id;
              this.save(subfolderId);
            })
            .catch(error => {
              this.errors = this.resolveError(error);
              this.isBusy = false;
            });
        } else {
          // walidacja wybranego rodzaju podfolderu
          if (this.selectedNoteSubfolder.typeId === undefined) {
            errors.selectedNoteSubfolder = 'wybór obowiązkowy';
            this.errors = errors;
            this.isBusy = false;
            return false;
          }
          // walidacja uzupełnienia cechy dla podfolderu typu obiekt_X
          if ((this.selectedNoteSubfolder.typeId === 100 || this.selectedNoteSubfolder.typeId === '100') && this.attribute === '') {
            errors.attribute = 'pole obowiązkowe';
            this.errors = errors;
            this.isBusy = false;
            return false;
          }
          // Dodanie nowego podfolderu dla notatek
          const form = {
            category_id: this.categoryId,
            noteable_type: this.noteableType,
            noteable_id: this.noteableId,
            note_subfolder_type_id: this.selectedNoteSubfolder.typeId,
          };
          if (this.selectedNoteSubfolder.typeId === 100 || this.selectedNoteSubfolder.typeId === '100') {
            form.note_subfolder_type_no = this.selectedNoteSubfolder.typeIdNo;
            form.note_subfolder_attribute = this.attribute;
          }
          NoteService.addNoteSubFolder(form)
            .then(response => {
              const subfolder = response.data.result;
              if (!subfolder || !subfolder.id) {
                this.$store.dispatch('notifications/add', {
                  type: 'error',
                  message: 'Błędnie zwrócony id nowego folderu, spróbuj ponownie później',
                });
                return false;
              }
              subfolderId = subfolder.id;
              this.save(subfolderId);
              return true;
            })
            .catch(error => {
              this.errors = this.resolveError(error);
              this.isBusy = false;
            });
        }
      } else {
        this.save(subfolderId);
      }
      return true;
    },
    save(subfolderId) {
      this.isBusy = true;
      if (this.filesToUpload.length > 0 && this.filesToUpload.length <= 50) {
        const form = [];
        for (let index = 0; index < this.filesToUpload.length; index += 1) {
          form.push(new FormData());
          form[index].append('noteable_id', this.noteableId);
          form[index].append('noteable_type', this.noteableType);
          form[index].append('category_id', this.categoryId);
          form[index].append('title', this.title);
          form[index].append('description', this.description);
          if (subfolderId !== '') {
            form[index].append('note_subfolder_id', subfolderId);
          }
          form[index].append('file', this.filesToUpload[index], this.filesToUpload[index].name);
          this.notesLoadTable.push(index);
          NoteService.addNote(form[index])
            .then(response => {
              this.notesLoadTable.shift();
              this.$emit('added', response.data);
              if (this.notesLoadTable.length === 0) {
                this.title = '';
                this.description = '';
                this.filesToUpload = [];
                this.isBusy = false;
                if (this.fileErrors.length > 0) {
                  this.$store.dispatch('notifications/add', { type: 'warning', message: 'Notatki/załączniki zostały dodane, jednak wystapiły pojedyncze błędy - sprawdź komunikaty' });
                } else {
                  this.$store.dispatch('notifications/add', { type: 'success', message: 'Notatki/załączniki zostały dodane' });
                }
                this.closeModal();
              }
            })
            .catch(error => {
              const errors = this.resolveError(error);
              Object.keys(errors).forEach(key => {
                errors[key] = `${this.filesToUpload[index].name}: ${errors[key]}`;
              });
              this.fileErrors.push(errors);
              this.notesLoadTable.shift();
              if (this.notesLoadTable.length === 0) this.isBusy = false;
            });
        }
      } else if (this.filesToUpload.length === 0) {
        const form = {
          noteable_id: this.noteableId,
          noteable_type: this.noteableType,
          category_id: this.categoryId,
          title: this.title,
          description: this.description,
        };
        NoteService.addNote(form)
          .then(response => {
            this.title = '';
            this.description = '';
            this.$emit('added', response.data);
            this.closeModal();
            this.isBusy = false;
          })
          .catch(error => {
            this.errors = this.resolveError(error);
            this.isBusy = false;
          });
      } else {
        this.fileErrors.push({ length: 'Możesz za jednym razem wczytać maksymalnie 50 plików.' });
        this.isBusy = false;
      }
      // wywalić
      return true;
    },
    closeModal() {
      this.show = false;
      this.$emit('close');
    },
  },
  watch: {
    listPrepared(newVal) {
      if (newVal && this.setSubfolder.id !== undefined && this.setSubfolder.id) {
        this.selectedNoteSubfolder = this.noteSubfolders.find(obj => Number(obj.id) === Number(this.setSubfolder.id));
        this.subfolderChanged();
      }
    },
    // showing(value) {
    //   if (value) {
    //     return document.querySelector('body').classList.add('overflow-hidden');
    //   }
    //   return document.querySelector('body').classList.remove('overflow-hidden');
    // },
  },
};
</script>
