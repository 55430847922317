import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

apiClient.interceptors.request.use(config => {
  if (apiClient.defaults.headers.common.Authorization !== axios.defaults.headers.common.Authorization) {
    apiClient.defaults.headers.common.Authorization = axios.defaults.headers.common.Authorization;
  }
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = axios.defaults.headers.common.Authorization;
  return config;
}, error => Promise.reject(error));

export default {
  updateNote(id, body) {
    const result = new Promise((resolve, reject) => {
      apiClient.patch(`/notes/${id}/update`, body)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  updateNoteSubfolderAttribute(id, body) {
    const result = new Promise((resolve, reject) => {
      apiClient.patch(`/note-subfolders/${id}/update`, body)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  checkSubfolderExists(id, body) {
    const result = new Promise((resolve, reject) => {
      apiClient.get(`/note-subfolders/${id}`, {
        params: body,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  addNoteSubFolder(body) {
    const result = new Promise((resolve, reject) => {
      apiClient.post('/note-subfolders', body)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  addNote(body) {
    const result = new Promise((resolve, reject) => {
      apiClient.post('/notes', body)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  deleteNote(id) {
    const result = new Promise((resolve, reject) => {
      apiClient.delete(`/notes/${id}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  deleteSelectedNotes(arr) {
    const result = new Promise((resolve, reject) => {
      apiClient.post('/notes/delete-many', { ids: arr })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  downloadCompleteOrder(orderId) {
    const result = new Promise((resolve, reject) => {
      apiClient.post(`create-complete-order-zip/${orderId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  downloadNotes(form) {
    const result = new Promise((resolve, reject) => {
      apiClient.post('create-notes-zip', form)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
};
