<template>
  <BaseModal
    :activeModal="show"
    @closeModal="$emit('close')"
    :cancel="false"
    :ok="true"
    okLabel="pobierz"
    @ok="download"
    :close="true"
    :bgClose="true"
    size="xl"
  >
    <template #header>
      Zdjęcie z notatki
      <div class="inline-block">
        <div class="flex">
          <a v-show="index > 0" href="#" class="naviButt" @click.prevent="changeImage('prev')" title="poprzednie">&lt;</a>
          <span v-show="index === 0" class="naviButt disabled">&lt;</span>
          <a v-show="index < imagesCount - 1" href="#" class="naviButt" @click.prevent="changeImage('next')" title="następne">&gt;</a>
          <span v-show="index === imagesCount - 1" class="naviButt disabled">&gt;</span>
          <a href="#" class="naviButt" @click.prevent="rortateImageRight" title="obróć w prawo"><font-awesome-icon icon="undo" class="mt-1 text-gray-700 hover:text-gray-100" flip="horizontal" /></a>
          <!-- <font-awesome-icon @click.prevent="rortateImageRight" title="obróć w prawo" icon="undo" size="lg" class="naviButt" /> -->
        </div>
      </div>
    </template>
    <template #default>
      <div class="mb-4" :class="{'rot-t': rotationDeg === 0, 'rot-r': rotationDeg === 90, 'rot-b': rotationDeg === 180, 'rot-l': rotationDeg === 270, }">
        <img class="rounded w-full" :src="`${apiUrl}/note-image/${token}/${note.uuid}`" alt="Obraz">
      </div>
    </template>
  </BaseModal>
</template>

<script>
// import axios from 'axios';

export default {
  name: 'ImageShow',
  props: {
    note: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
    },
    imagesCount: {
      type: Number,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      show: false,
      rotationDeg: 0,
    };
  },
  mounted() {
    this.show = true;
  },
  methods: {
    download() {
      const newWindow = window.open(null, this.note.uuid);
      newWindow.location.href = `${process.env.VUE_APP_API_URL}/note-file/download/${this.token}/${this.note.uuid}`;
    },
    changeImage(dir) {
      let { index } = this;
      if (dir === 'prev') {
        index -= 1;
      } else {
        index += 1;
      }
      this.$emit('changeImage', index);
    },
    rortateImageRight() {
      this.rotationDeg = this.rotationDeg < 270 ? this.rotationDeg + 90 : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
  .naviButt {
    @apply flex;
    @apply flex-none;
    @apply border;
    @apply border-gray-500;
    @apply bg-gray-200;
    @apply rounded;
    @apply px-1;
    @apply py-0;
    @apply mx-2;
    @apply text-sm;
    @apply no-underline;
    &:hover {
      @apply bg-gray-700;
      @apply text-gray-200;
    }
    &.disabled {
      @apply border-gray-400;
      @apply bg-gray-300;
      @apply text-gray-400;
    }
  }
  .rot-t {
    transform: rotate(0deg);
  }
  .rot-r {
    transform: rotate(90deg);
  }
  .rot-b {
    transform: rotate(180deg);
  }
  .rot-l {
    transform: rotate(270deg);
  }
</style>
