/* eslint-disable import/prefer-default-export */
export const errorHandler = {
  methods: {
    /**
     * Metoda zarządzająca systemem komunikatów i zachowań zależnie od błędu zwracanego z API
     * @param {Object} error Błąd zwracany z API
     */
    resolveError(error) {
      if (error.response && error.response.status === 401) {
        this.$store.dispatch('logout', this.$router.history.current.fullPath)
          .then(() => {
            this.$router.push({ name: 'login' });
          });
      } else if (error.response && error.response.status === 403) {
        this.$store.dispatch('notifications/add', {
          type: 'error',
          message: error.response.data.message ? error.response.data.message : 'E3KW: Brak uprawnień do funkcji',
        });
      } else if (error.response && error.response.status === 404) {
        this.$store.dispatch('notifications/add', {
          type: 'error',
          message: error.response.data.message ? error.response.data.message : 'E4KW: Nie dnaleziono zasobu',
        });
        this.$router.replace({ name: 404, params: { resource: this.$route.meta.niceName } });
      } else if (error.response && error.response.status === 422) {
        this.$store.dispatch('notifications/add', {
          type: 'error',
          message: 'Nieprawidłowe wartości pól:',
        });
        const { errors } = error.response.data;
        const errorsArrToStr = {};
        if (errors !== undefined && typeof errors === 'object') {
          Object.keys(errors).forEach(key => {
            errors[key].forEach((mess, index) => {
              this.$store.dispatch('notifications/add', { type: 'error', message: `${mess}` });
              errorsArrToStr[key] = index === 0 ? mess : `${errorsArrToStr[key]} ${mess}`;
            });
          });
        }
        return errorsArrToStr;
      } else {
        this.$store.dispatch('notifications/add', {
          type: 'error',
          message: 'Wystapił błąd połączenia z serwerem...',
        });
        this.$router.push({ name: 500, params: { resource: this.$route.meta.niceName } });
      }
      return false;
    },
  },
};
