<template>
  <NotLogged
    :welcome="welcome"
    formTitle="Logowanie"
    :afterMessage="message"
  >
    <template #form>
      <form @submit.prevent="login" autocomplete="off">
        <input
          id="input-1"
          @focus = "message = ''"
          ref="username"
          name="username"
          v-model="form.login"
          placeholder="login lub email"
          type="text"
          :class="{'border-red-500': message.length > 0 }"
          :disabled="isBusy"
          required
        >
        <div class="relative">
        <input
          id="input-2"
          @focus = "message = ''"
          name="password"
          v-model="form.password"
          placeholder="hasło"
          :type="passVisible ? 'text' : 'password'"
          :class="{'border-red-500': message.length > 0 }"
          :disabled="isBusy"
          required
        >
        <div @click="passVisible = !passVisible" class="absolute top-0 pt-4 text-gray-500 cursor-pointer" style="right:-2em" :title="passVisible ? 'ukryj hasło' : 'pokaż hasło'">
          <font-awesome-icon :icon="passVisible ? 'eye' : 'eye-slash'" size="lg"/>
        </div>
        </div>
        <button type="submit" class="btn btn-primary w-full rounded-sm mt-2" :disabled="isBusy">
          <span v-if="!isBusy">Zaloguj</span>
          <span v-else>Logowanie...</span>
        </button>
        <div class="block text-right mb-4 mt-1">
          <router-link :to="{ name: 'passwordEmail' }" title="Kliknij, aby wygenerować nowe hasło">Nie pamiętam hasła</router-link>
        </div>
      </form>
    </template>
  </NotLogged>
</template>

<script>
import NotLogged from '../components/NotLogged.vue';

export default {
  name: 'Login',
  components: {
    NotLogged,
  },
  data() {
    return {
      form: {
        login: '',
        password: '',
      },
      message: '',
      isBusy: false,
      welcome: {
        content: `Witamy w aplikacji e-Stimates, wspomagającej likwidację szkód. Zaloguj się przy pomocy nadanej przez administratora nazwy
          użytkownika lub adresu email powiązanego z kontem. Jeśli zapomniałeś hasło lub logujesz się po raz pierwszy, kliknij w link
          "Nie pamiętam hasła" i podążaj za instrukcją. Miłego dnia!`,
        footer: 'Zespół Estimates Likwidacja Szkód',
      },
      passVisible: false,
    };
  },
  computed: {
    backToURL() {
      return this.$store.getters.backToURL;
    },
  },
  mounted() {
    this.$refs.username.focus();
  },
  methods: {
    login() {
      this.isBusy = true;
      this.$store.dispatch('login', this.form)
        .then(() => {
          if (this.backToURL !== null) {
            this.$router.push(this.backToURL);
            this.$store.dispatch('clearBackToURL');
          } else {
            this.$router.push({ name: 'orders' });
          }
          this.isBusy = false;
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            this.message = error.response.data.message;
          } else {
            this.message = 'Wystąpił problem połączenia z serwerem docelowym. Spróbuj ponownie później';
          }
          this.form = { login: '', password: '' };
          this.isBusy = false;
        });
    },
  },
};
</script>
<style scoped>
  input {
    @apply bg-white;
    @apply border;
    @apply rounded-sm;
    @apply py-2;
    @apply px-4;
    @apply block;
    @apply w-full;
    @apply appearance-none;
    @apply leading-normal;
    @apply text-2xl;
    @apply my-2;
  }
  input:focus {
    @apply outline-none;
    @apply border-blue-300;
  }
</style>
