<template>
  <Transition>
    <div
      v-show="show"
      class="absolute flex items-center justify-center inset-0 blur"
    >
      <div class="bg-gray-200 py-5 px-10 border rounded-lg shadow-lg max-w-md md:max-w-lg lg:max-w-2xl">
        <p><strong>{{ message }}</strong></p>
        <div class="flex justify-center">
          <button
            @click="onCancel"
            class="my-3 px-3 py-1 bg-gray-500 text-white hover:bg-gray-600 font-semibold tracking-wide rounded"
          >
            Anuluj
          </button>
          <button
            @click="onOk"
            class="my-3 ml-3 px-3 py-1 bg-yellow-400 text-gray-700 hover:bg-yellow-500 font-bold tracking-wide rounded"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'Confirm',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    message() {
      return this.$store.getters['confirmation/message'] || 'Czy jesteś pewny?';
    },
    callback() {
      return this.$store.getters['confirmation/callback'];
    },
  },
  methods: {
    onOk() {
      this.callback();
      this.$store.dispatch('confirmation/closeDialog');
    },
    onCancel() {
      this.$store.dispatch('confirmation/closeDialog');
    },
  },
};
</script>

<style scoped>
  .blur {
    background-color: rgba(200, 200, 200, 0.4);
    backdrop-filter: blur(2px);
    z-index: 100;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.4s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
