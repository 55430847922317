<template>
  <div class="h-10 flex flex-shrink-0 items-center justify-between shadow">
    <div class="flex items-center h-full">
      <div @click="$emit('toggleMenu')" class="flex h-full px-2 bg-blue-900 hover:bg-blue-800 cursor-pointer rounded-r-md">
        <span class="inline-block self-center text-blue-400" v-html="menuVisible ? '&lt;&lt;' : '&gt;&gt;'">&lt;&lt;</span>
      </div>
      <span class="inline-block ml-5 font-semibold text-gray-600">{{ breadcrumbs }}</span>
    </div>
    <div class="flex items-center h-full">
      <router-link :to="{ name: 'profile' }" title="Ustawienia użytkownika" class="flex items-center px-2 text-gray-500 hover:text-white hover:no-underline hover:bg-blue-400 h-full">
        <svg class="inline h-5 w-5 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zM7 6v2a3 3 0 1 0 6 0V6a3 3 0 1 0-6 0zm-3.65 8.44a8 8 0 0 0 13.3 0 15.94 15.94 0 0 0-13.3 0z"/>
        </svg>
        <span class="ml-1 font-semibold">{{ userName }}</span>
      </router-link>
      <router-link :to="{name: 'logout'}" class="flex items-center pr-4 pl-2 text-blue-500 hover:text-white hover:bg-blue-400 h-full" title="Wyloguj">
        <svg class="inline h-5 w-5 fill-current" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20">
          <path d="M14 10L8 5v3H1v4h7v3l6-5zm3 7H9v2h8c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2H9v2h8v14z"/>
        </svg>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainTopBar',
  props: {
    menuVisible: {
      type: Boolean,
      required: true,
    },
    breadcrumbs: {
      type: String,
      default: '',
    },
    userName: {
      type: String,
      required: true,
    },
  },
};
</script>
