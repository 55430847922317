import axios from 'axios';

/* eslint-disable no-shadow */
const state = {
  managers: [],
};

const mutations = {
  SET(state, roles) {
    state.managers = roles;
  },
};

const actions = {
  getManagers({ commit }) {
    axios.get(`${process.env.VUE_APP_API_URL}/managers`)
      .then(response => commit('SET', response.data))
      .catch(error => console.log(error));
  },
};

const getters = {
  managers() {
    return state.managers;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
