import axios from 'axios';

/* eslint-disable no-shadow */
const state = {
  employeeRoles: [],
};

const mutations = {
  SET(state, roles) {
    state.employeeRoles = roles;
  },
};

const actions = {
  getEmployeeRoles({ commit }) {
    axios.get(`${process.env.VUE_APP_API_URL}/employee-roles`)
      .then(response => commit('SET', response.data))
      .catch(error => console.log(error));
  },
};

const getters = {
  employeeRoles() {
    return state.employeeRoles;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
