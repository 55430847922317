import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import notifications from '@/store/modules/notifications';
import confirmation from '@/store/modules/confirmation';
import regions from '@/store/modules/regions';
import roles from '@/store/modules/roles';
import managers from '@/store/modules/managers';
import updates from '@/store/modules/updates';
import orders from '@/store/modules/orders';
import notes from '@/store/modules/notes';
import salaries from '@/store/modules/salaries';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    notifications,
    confirmation,
    regions,
    updates,
    roles,
    managers,
    orders,
    notes,
    salaries,
  },
  state: {
    user: null,
    backToURL: null,
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.user = userData;
      localStorage.setItem('user', JSON.stringify(userData));
      axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`;
    },
    LOGOUT(state) {
      state.user = null;
      localStorage.removeItem('user');
      axios.defaults.headers.common.Authorization = null;
    },
    STORE_CURRENT_URL(state, url) {
      state.backToURL = url !== '' ? url : null;
    },
  },
  actions: {
    login({ commit }, credentials) {
      return axios
        .post(`${process.env.VUE_APP_API_URL}/login`, credentials)
        .then(response => {
          commit('SET_USER_DATA', response.data.result);
        });
    },
    logout({ commit }, url = null) {
      const result = new Promise(resolve => {
        axios.post(`${process.env.VUE_APP_API_URL}/logout`)
          .then(() => {
            commit('STORE_CURRENT_URL', url);
            commit('LOGOUT', url);
            resolve();
          })
          .catch(() => {
            commit('STORE_CURRENT_URL', url);
            commit('LOGOUT', url);
            resolve();
          });
      });
      return result;
    },
    storeBackToURL({ commit }, url) {
      commit('STORE_CURRENT_URL', url);
    },
    clearBackToURL({ commit }) {
      commit('STORE_CURRENT_URL', null);
    },
  },
  getters: {
    loggedIn(state) {
      return !!state.user;
    },
    user(state) {
      return state.user;
    },
    backToURL(state) {
      return state.backToURL;
    },
  },
});
