<template>
  <div class="fixed max-w-xs md:max-w-md bottom-0 right-0 pr-2"  style="z-index: 99">
    <NotificationBar
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
    />
  </div>
</template>

<script>
import NotificationBar from '@/components/NotificationBar.vue';
import { mapState } from 'vuex';

export default {
  components: {
    NotificationBar,
  },
  computed: mapState('notifications', ['notifications']),
};
</script>
