/* eslint-disable no-shadow */
const state = {
  notifications: [],
};

let nextId = 1;

const mutations = {
  PUSH(state, notification) {
    state.notifications.push({
      ...notification,
      id: nextId += 1,
    });
  },
  DELETE(state, notificationToRemove) {
    state.notifications = state.notifications.filter(notification => notification.id !== notificationToRemove.id);
  },
};

const actions = {
  add({ commit }, notification) {
    commit('PUSH', notification);
  },
  remove({ commit }, notificationToRemove) {
    commit('DELETE', notificationToRemove);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
