import axios from 'axios';

/* eslint-disable no-shadow */
const state = {
  statuses: [],
  types: [],
  contractors: [],
};

const mutations = {
  SET_STATUSES(state, statuses) {
    state.statuses = statuses;
  },
  SET_TYPES(state, types) {
    state.types = types;
  },
  SET_CONTRACTORS(state, contractors) {
    state.contractors = contractors;
  },
};

const actions = {
  getStatuses({ commit }) {
    axios.get(`${process.env.VUE_APP_API_URL}/order-statuses`)
      .then(response => commit('SET_STATUSES', response.data))
      .catch(error => console.log(error));
  },
  getTypes({ commit }) {
    axios.get(`${process.env.VUE_APP_API_URL}/order-types`)
      .then(response => commit('SET_TYPES', response.data))
      .catch(error => console.log(error));
  },
  getContractors({ commit }) {
    axios.get(`${process.env.VUE_APP_API_URL}/contractor-list`)
      .then(response => commit('SET_CONTRACTORS', response.data))
      .catch(error => console.log(error));
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
