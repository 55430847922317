<template>
  <div class="sub-card" ref="container">
    <div v-if="subTitle !== ''" class="sub-card-header">
      <span class="sub-card-header-title">{{ subTitle }}
        <a v-if="attribute && allowAdd" href="#" @click.prevent="showNoteSubfAttrEdit = true" class="text-xs" title="kliknij, aby edytować cechę obiektu">
          (<i>{{ attribute }}</i> <font-awesome-icon icon="edit" />)
        </a>
        <span v-else-if="attribute" class="text-xs text-gray-500">(<i>{{ attribute }}</i>)</span>
      </span>
      <div class="relative w-auto">
        <span v-show="!showSelect && allowAdd" @click="addNote" class="text-gray-500 hover:text-blue-600 cursor-pointer mr-2" title="Dodaj">
          <font-awesome-icon class="" icon="plus" size="xs" />
          <font-awesome-icon icon="file-alt" />
        </span>
        <span @click="downloadZip" v-show="!showSelect" class="text-gray-500 hover:text-teal-600 cursor-pointer mr-2" title="Pobierz podkatalog do ZIP">
          <font-awesome-icon icon="download" />
        </span>
        <span v-show="!showSelect && notes.length > 1" @click="openSelectNotes" class="text-gray-500 hover:text-purple-600 cursor-pointer" title="Wybierz...">
          <font-awesome-icon icon="th-list" />
        </span>
        <div v-show="showSelect" class="absolute top-0 right-0 mt-2 py-1 px-3 bg-blue-100 border rounded shadow-xl">
          <div class="flex justify-end">
            <span @click="downloadZip" class="text-gray-500 hover:text-teal-600 ml-2 cursor-pointer" title="Pobierz wybrane do ZIP">
              <font-awesome-icon icon="download" />
            </span>
            <span v-show="user.abilities.includes('can_everything')" @click="delSelectedHandler" class="text-gray-500 hover:text-red-600 ml-2 cursor-pointer" title="Usuń wybrane notatki i załączniki">
              <font-awesome-icon icon="trash-alt" />
            </span>
            <span @click="closeSelectNotes" class="text-gray-500 hover:text-gray-700 ml-2 cursor-pointer" title="Anuluj wybieranie">
              <font-awesome-icon icon="times" size="lg" />
            </span>
          </div>
          <span class="text-sm whitespace-no-wrap">Wybranych: {{ numberSelected }}</span>
        </div>
      </div>
    </div>
    <div class="sub-card-content" :class="{'border-t': subTitle !== ''}">

      <div class="sub-card-data-section pt-1"> <!-- data section -->
        <div v-for="(note, index) in pagiNotes" :key="note.id" class="flex items-center w-full mx-3 border-b pb-2 mb-2">
          <input v-if="showSelect" type="checkbox" class="w-auto mr-2" v-model="form[note.id]">
          <div v-if="Number(note.type_id) === 3" class="flex justify-center bg-gray-300 rounded mr-3 w-16" title="notatka tekstowa">
            <font-awesome-icon icon="file-alt" size="4x" class="p-1" />
          </div>
          <div v-else-if="Number(note.type_id) === 1" class="rounded mr-3 cursor-pointer" title="kliknij, aby otworzyć/pobrać załącznik">
            <img v-if="note.archived == 0" @click="showImage(note, index)" class="rounded w-16" :src="`${apiUrl}/note-image/thumb/${token}/${note.uuid}`" alt="Miniaturka">
            <img v-else class="rounded w-16" :src="require('@/assets/_zarchiwizowane.png')" alt="Miniaturka" title="Załącznik zarchiwizowany">
          </div>
          <div v-else class="flex justify-center bg-yellow-600 rounded mr-3 w-16 cursor-pointer" title="kliknij, aby otworzyć/pobrać załącznik">
            <img v-if="note.archived == 1" class="rounded w-16" :src="require('@/assets/_zarchiwizowane.png')" alt="Miniaturka" title="Załącznik zarchiwizowany">
            <font-awesome-icon v-else-if="note.file_type === 'application/pdf'" @click="openFile(note)" icon="file-pdf" size="4x" class="p-1" />
            <font-awesome-icon v-else @click="downloadFile(note)" icon="file-pdf" size="4x" class="p-1" />
          </div>
          <div class="flex flex-col w-full">
            <div class="flex justify-between text-sm">
              <span>
                <span class="text-xs mr-2"><em>{{ note.created_at |dateTimePl }}</em></span>
                <span v-if="note.user.person" class="text-blue-600 hover:underline cursor-pointer mr-2">
                  <a :href="`mailto:${note.user.email}`">{{ note.user.person.first_name }} {{ note.user.person.surname }}</a>
                </span>
                <span v-else-if="note.user.name === user.username" class="text-teal-600 font-semibold  mr-2">Notatka własna</span>
                <span v-else class="text-gray-500 font-semibold  mr-2">Notatka innego użytkownika</span>
              </span>
              <span v-if="!showSelect">
                <font-awesome-icon v-show="Number(user.id) === Number(note.user_id) || user.abilities.includes('can_everything')" @click="editNote(note)" class="text-gray-500 hover:text-blue-600 cursor-pointer" icon="edit" title="edytuj" />
                <font-awesome-icon v-show="Number(user.id) === Number(note.user_id) || user.abilities.includes('can_everything')" @click="delNoteHandler(note.id)" class="ml-1 text-gray-500 hover:text-red-600 cursor-pointer" icon="trash-alt" title="usuń" />
              </span>
            </div>
            <span class="font-bold text-gray-700">
              {{ note.title }}
            </span>
            <span class="text-sm" v-html="note.description_html"></span>
            <span v-if="note.filename" class="text-sm"><em>{{ note.filename }}</em></span>
          </div>
        </div>
        <div v-if="notes.length > 0 && notes.length > 10" class="pl-3 text-sm">
          <em>Wyświetla: {{ pagiNotes.length }} z {{ notes.length }}</em>
          <span class="ml-3"></span>
          <button
            @click="notesLimit += 10"
            class="px-1 font-semibold text-xs border border-r-0 border-gray-500 rounded-l bg-gray-300"
            :class="{'hover:bg-gray-700 hover:text-white': pagiNotes.length < notes.length, 'text-gray-600': pagiNotes.length >= notes.length}"
            :disabled="pagiNotes.length >= notes.length"
            title="pokaż kolejne 10"
          >więcej</button>
          <button
            @click="notesLimit = notes.length"
            class="px-1 font-semibold text-xs border border-r-0 border-gray-500 bg-gray-300"
            :class="{'hover:bg-gray-700 hover:text-white': pagiNotes.length < notes.length, 'text-gray-600': pagiNotes.length >= notes.length}"
            :disabled="pagiNotes.length >= notes.length"
            title="pokaż wszystkie załączniki"
          >wszystkie</button>
          <button
            @click="notesLimit = 10"
            class="px-1 font-semibold text-xs border border-gray-500 rounded-r bg-gray-300"
            :class="{'hover:bg-gray-700 hover:text-white': pagiNotes.length > 10, 'text-gray-600': pagiNotes.length <= 10}"
            :disabled="pagiNotes.length <=10"
            title="pokaż 10 najnowszych załączników"
          >mniej</button>
        </div>
      </div>
    </div> <!-- end card-content -->

    <NoteEdit
      v-if="showNoteEdit"
      @close="showNoteEdit = false"
      @changed="noteChanged"
      :note="noteToEdit"
      :token="token"
    />
    <NoteSubfolderAttrEdit
      v-if="showNoteSubfAttrEdit"
      @close="showNoteSubfAttrEdit = false"
      @changed="attributeChanged"
      :attribute="attribute"
      :noteSubfolderId="noteSubfolderId"
    />
    <ImageShow
      v-if="showGallery"
      @close="showGallery = false"
      @changeImage="changeImageShow"
      :note="noteToShowImage.note"
      :index="noteToShowImage.imageIndex"
      :imagesCount="images.length"
      :token="token"
    />

    <OrderZipInfo
      :activeModal="zipInfo"
      :zipUrl="zipUrl"
      :done="zipReady"
      @closeModal="closeOrderZipInfo"
    />
  </div> <!-- card -->
</template>

<script>
import ImageShow from '@/components/ImageShow.vue';
import NoteEdit from './NoteEdit.vue';
import NoteSubfolderAttrEdit from './NoteSubfolderAttrEdit.vue';
import NoteService from '../services/NoteService';
import OrderZipInfo from './OrderZipInfo.vue';
import { errorHandler } from '../mixins/errorHandler';
import { confirm } from '../mixins/confirm';

export default {
  name: 'NotesSubfolder',
  components: {
    NoteEdit,
    NoteSubfolderAttrEdit,
    ImageShow,
    OrderZipInfo,
  },
  mixins: [errorHandler, confirm],
  props: {
    notes: {
      type: Array,
      default: () => [],
    },
    roles: {
      type: Object,
      default: () => {},
    },
    subTitle: {
      type: String,
      required: true,
    },
    attribute: {
      type: String,
      default: '',
    },
    categoryId: {
      type: [String, Number],
      required: true,
    },
    noteableId: {
      type: [String, Number],
      required: true,
    },
    noteableType: {
      type: String,
      required: true,
    },
    noteSubfolderId: {
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showNoteEdit: false,
      showNoteSubfAttrEdit: false,
      showGallery: false,
      showSelect: false,
      zipInfo: false,
      zipUrl: '',
      zipReady: false,
      noteToShowImage: {
        imageIndex: -1,
        note: {},
      },
      noteToEdit: {},
      apiUrl: process.env.VUE_APP_API_URL,
      form: {},
      notesLimit: 10,
    };
  },
  computed: {
    pagiNotes() {
      return this.notes.slice(0, this.notesLimit);
    },
    user() {
      return this.$store.state.user;
    },
    allowAdd() {
      return this.user.abilities.includes('can_everything')
        || (this.user.abilities.includes('registrar') && this.noteableType === 'App\\Order' && ['10', '40'].includes(this.categoryId))
        || (this.roles.is_inspector && this.noteableType === 'App\\Order' && ['10', '20'].includes(this.categoryId))
        || (this.roles.is_valuator && this.noteableType === 'App\\Order' && ['10', '30'].includes(this.categoryId))
        || (this.roles.is_contractor && this.noteableType === 'App\\Order' && Number(this.categoryId) === 40)
        || this.noteableType === 'App\\Employee';
    },
    numberSelected() {
      return this.notesSelected.length;
    },
    notesSelected() {
      const idsArr = [];
      Object.keys(this.form).forEach(key => {
        if (this.form[key]) {
          idsArr.push(key);
        }
      });
      return idsArr;
    },
    images() {
      const imagesArr = this.notes.filter(obj => obj.type_id === 1 || obj.type_id === '1');
      return imagesArr;
    },
    subfolderToAdd() {
      return {
        attribute: this.attribute,
        id: this.noteSubfolderId,
        name: this.subTitle,
        type_id: this.notes[0].note_subfolder ? this.notes[0].note_subfolder.note_subfolder_type_id : null,
        type_id_no: this.notes[0].note_subfolder ? this.notes[0].note_subfolder.note_subfolder_type_no : null,
        visible: true,
      };
    },
  },
  destroyed() {
    document.removeEventListener('click', this.isSelectClicked);
  },
  methods: {
    addNote() {
      this.$emit('addNote', this.subfolderToAdd);
    },
    noteChanged(note) {
      this.$emit('changed', note);
    },
    editNote(note) {
      this.noteToEdit = note;
      this.showNoteEdit = true;
    },
    attributeChanged(newVal) {
      this.$emit('subfAttrChanged', newVal);
    },
    delNoteHandler(id) {
      this.confirm(() => this.delNote(id), 'Potwierdź usunięcie notatki');
    },
    delNote(id) {
      NoteService.deleteNote(id)
        .then(() => {
          this.$emit('deleted', id);
          this.$store.dispatch('notifications/add', { type: 'success', message: 'Notatka usunięta' });
        })
        .catch(error => {
          this.resolveError(error);
        });
    },
    delSelectedHandler() {
      if (this.notesSelected.length > 0) {
        this.confirm(() => this.delSelected(this.notesSelected), `Potwierdź usunięcie zaznaczonych ${this.numberSelected} notatek`);
      }
    },
    delSelected(noteIdsArr) {
      if (this.notesSelected.length > 0) {
        NoteService.deleteSelectedNotes(noteIdsArr)
          .then(() => {
            this.$emit('deletedMany', noteIdsArr);
            this.$store.dispatch('notifications/add', { type: 'success', message: 'Notatka usunięta' });
            this.closeSelectNotes();
          })
          .catch(error => {
            this.resolveError(error);
          });
      }
    },
    showImage(note) {
      const imageIndex = this.images.findIndex(obj => obj.uuid === note.uuid);
      this.showGallery = true;
      this.noteToShowImage = { imageIndex, note };
    },
    changeImageShow(imageIndex) {
      this.noteToShowImage = {
        imageIndex,
        note: this.images[imageIndex],
      };
    },
    openFile(note) {
      const newWindow = window.open(null, note.uuid);
      newWindow.location.href = `${process.env.VUE_APP_API_URL}/note-file/open/${this.token}/${note.uuid}`;
    },
    downloadFile(note) {
      const newWindow = window.open(null, note.uuid);
      newWindow.location.href = `${process.env.VUE_APP_API_URL}/note-file/download/${this.token}/${note.uuid}`;
    },
    downloadZip() {
      if ((this.showSelect && this.notesSelected.length === 0) || this.notes.length === 0) {
        return false;
      }
      const form = {
        noteable_type: this.noteableType,
        noteable_id: this.noteableId,
        category_id: this.categoryId,
        ids: this.notesSelected,
        note_subfolder_id: this.noteSubfolderId,
      };
      this.zipInfo = true;
      this.zipUrl = '';
      NoteService.downloadNotes(form)
        .then(response => {
          if (this.showSelect) this.closeSelectNotes();
          this.zipUrl = response.data.url;
          this.zipReady = true;
          // const newWindow = window.open(null, 'downloadZIP');
          // newWindow.location.href = response.data.url;
        })
        .catch(error => {
          this.resolveError(error);
        });
      return true;
    },
    closeOrderZipInfo() {
      this.zipInfo = false;
      this.zipUrl = '';
      this.zipReady = false;
    },
    openSelectNotes() {
      this.showSelect = true;
      document.addEventListener('click', this.isSelectClicked);
    },
    isSelectClicked(e) {
      const { container } = this.$refs;
      const { target } = e;
      if (!container.contains(target)) {
        this.closeSelectNotes();
      }
    },
    closeSelectNotes() {
      this.showSelect = false;
      document.removeEventListener('click', this.isSelectClicked);
      this.form = {};
    },
  },
};
</script>

<style lang="scss" scoped>
  .sub-card {
    @apply w-full;
  }
  .sub-card-header {
     @apply flex;
     @apply justify-between;
     @apply px-3;
     @apply py-0;
     &.dark {
       @apply bg-gray-900;
       @apply text-gray-200;
       @apply border-gray-700;
     }
  }
  .sub-card-header-title {
    @apply pl-2;
    @apply font-semibold;
    @apply text-sm;
    @apply text-teal-700;
  }
  .sub-card-content {
    @apply px-3;
    @apply pt-1;
    @apply pb-3;
    @apply border-b;
    @apply border-gray-400;
    &.dark {
      @apply bg-gray-800;
      @apply text-gray-200;
      @apply border-gray-700;
    }
  }
  .sub-card-data-section {
    @apply flex;
    @apply flex-wrap;
    @apply -mx-3;
  }
</style>
