<template>
  <AlertBig>
    <template #title>
      Ups!
    </template>
    <template #subtitle>
      Wybrany element nie istnieje.
    </template>
    <template #default>
      Wybierz pozycję z menu lub wyszukaj w przeznaczonym do tego miejscu. Możesz kliknąć przycisk wstecz, aby wrócić do poprzedniego okna
    </template>
    <template #button>
      <button class="btn btn-primary" @click="$router.go(-1)">Wróć</button>
    </template>
  </AlertBig>
</template>

<script>
import AlertBig from '../components/AlertBig.vue';

export default {
  name: 'NotFound',
  components: { AlertBig },
  props: {
    resource: {
      type: String,
      default: '',
    },
  },
};
</script>
