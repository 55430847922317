<template>
  <div>
    <img class="rounded-lg w-1/4" :src="require('@/assets/keyboard-3085978_1280.jpg')" alt="Testowy obrazek">
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
};
</script>
