<template>
  <div class="card" ref="container">
    <div class="card-header">
      <span class="card-header-title">{{ title }}</span>
      <div class="relative w-auto">
        <span v-show="allowAdd" @click="addNote({})" class="text-gray-500 hover:text-blue-600 cursor-pointer mr-2" title="Dodaj">
          <font-awesome-icon class="" icon="plus" size="xs" />
          <font-awesome-icon icon="file-alt" />
        </span>
        <span v-show="notes.length > 0" @click="downloadZip" class="text-gray-500 hover:text-teal-600 cursor-pointer mr-2" title="Pobierz wszystkie do ZIP">
          <font-awesome-icon icon="download" />
        </span>
      </div>
    </div>
    <div class="card-content">

      <div class="card-data-section pt-1"> <!-- data section -->

        <div v-if="hasSubfolders" class="flex mx-3 text-xs text-gray-700">
          <span class="font-semibold">Załączniki:</span>
          <div>
            <button
              v-for="(noteSubf, index) in subfolderNotes"
              :key="index"
              @click="showSubfolderNotes(noteSubf.id)"
              class="px-1 m-1 border rounded-sm active:text-green-600"
              :class="{'bg-yellow-100 text-gray-600 border-yellow-400 font-semibold cursor-default': visibleSubfolderNotes && visibleSubfolderNotes.id === noteSubf.id, 'border-blue-500 text-blue-500 hover:border-orange-700 hover:text-orange-700': !visibleSubfolderNotes || visibleSubfolderNotes.id !== noteSubf.id}"
            >
              {{ noteSubf.name }}
            </button>
          </div>
        </div>

        <div v-if="visibleSubfolderNotes" class="m-2 border border-b-0 w-full bg-yellow-100">
          <NotesSubfolder
            :subTitle="hasSubfolders ? visibleSubfolderNotes.name : ''"
            :attribute="hasSubfolders ? visibleSubfolderNotes.attribute : ''"
            :noteableId="noteableId"
            :noteableType="noteableType"
            :categoryId="categoryId"
            :noteSubfolderId="visibleSubfolderNotes.id"
            :notes="visibleSubfolderNotes.notes"
            :roles="roles"
            :token="token"
            @addNote="addNote"
            @added="noteAdded"
            @changed="noteChanged"
            @subfAttrChanged="subfAttrChanged"
            @deleted="noteDeleted"
            @deletedMany="noteDeletedMany"
          />
        </div>

        <NotesSubfolder
          v-if="generalNotes !== undefined && generalNotes.notes.length > 0"
          :subTitle="hasSubfolders ? generalNotes.name : ''"
          :noteableId="noteableId"
          :noteableType="noteableType"
          :categoryId="categoryId"
          :noteSubfolderId="generalNotes.id"
          :notes="generalNotes.notes"
          :roles="roles"
          :token="token"
          @addNote="addNote"
          @added="noteAdded"
          @changed="noteChanged"
          @deleted="noteDeleted"
          @deletedMany="noteDeletedMany"
        />
        <span v-else-if="!hasSubfolders" class="px-3 py-1 text-gray-500"><em>Brak notatek</em></span>

      </div>
    </div> <!-- end card-content -->
    <NoteAdd
      v-if="showNoteAdd"
      @close="closeAddNote"
      @added="noteAdded"
      :categoryId="categoryId"
      :noteableId="noteableId"
      :noteableType="noteableType"
      :subfolders="subfolderNotes"
      :setSubfolder="subfolderToAdd"
    />
    <OrderZipInfo
      :activeModal="zipInfo"
      :zipUrl="zipUrl"
      :done="zipReady"
      @closeModal="closeOrderZipInfo"
    />
  </div> <!-- card -->
</template>

<script>
import NotesSubfolder from './NotesSubfolder.vue';
import NoteAdd from './NoteAdd.vue';
import NoteService from '../services/NoteService';
import OrderZipInfo from './OrderZipInfo.vue';
import { errorHandler } from '../mixins/errorHandler';

export default {
  name: 'Notes',
  components: {
    NotesSubfolder,
    NoteAdd,
    OrderZipInfo,
  },
  mixins: [errorHandler],
  props: {
    notes: {
      type: Array,
      default: () => [],
    },
    roles: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      required: true,
    },
    categoryId: {
      type: [String, Number],
      required: true,
    },
    noteableId: {
      type: [String, Number],
      required: true,
    },
    noteableType: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showNoteAdd: false,
      zipInfo: false,
      zipUrl: '',
      zipReady: false,
      apiUrl: process.env.VUE_APP_API_URL,
      visibleSubfolderNotes: null,
      subfolderToAdd: {},
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    allowAdd() {
      return this.user.abilities.includes('can_everything')
        || (this.user.abilities.includes('registrar') && this.noteableType === 'App\\Order' && ['10', '40'].includes(this.categoryId))
        || (this.roles.is_inspector && this.noteableType === 'App\\Order' && ['10', '20'].includes(this.categoryId))
        || (this.roles.is_valuator && this.noteableType === 'App\\Order' && ['10', '30'].includes(this.categoryId))
        || (this.roles.is_contractor && this.noteableType === 'App\\Order' && Number(this.categoryId) === 40)
        || this.noteableType === 'App\\Employee';
    },
    hasSubfolders() {
      return this.notes.filter(obj => obj.note_subfolder_id).length > 0;
    },
    generalNotes() {
      const notes = this.notes.filter(obj => !obj.note_subfolder_id);
      return {
        id: null,
        name: 'Notatki ogólne',
        type_id: null,
        type_id_no: null,
        attribute: null,
        notes,
        visible: true,
      };
    },
    subfolderNotes() {
      const subfolderUniqueIds = this.notes.map(obj => obj.note_subfolder_id).filter((val, index, self) => val && self.indexOf(val) === index);
      const subNotes = [];
      subfolderUniqueIds.forEach(subfolderId => {
        const subIdNotes = this.notes.filter(obj => obj.note_subfolder_id === subfolderId);
        let name = 'Notatki ogólne';
        let subfolderTypeId = null;
        let subfolderTypeNo = null;
        let subfolderAttribute = null;
        if (subfolderId) {
          subfolderTypeId = Number(subIdNotes[0].note_subfolder.note_subfolder_type_id);
          name = subIdNotes[0].note_subfolder.note_subfolder_type.name;
          subfolderTypeNo = subIdNotes[0].note_subfolder.note_subfolder_type_no ? Number(subIdNotes[0].note_subfolder.note_subfolder_type_no) : null;
          if (subfolderTypeId === 100) {
            name = `${name} ${subfolderTypeNo}`;
          }
          subfolderAttribute = subIdNotes[0].note_subfolder.note_subfolder_attribute;
        }
        subNotes.push({
          id: subfolderId,
          name,
          type_id: subfolderTypeId,
          type_id_no: subfolderTypeNo,
          attribute: subfolderAttribute,
          notes: subIdNotes,
          visible: !subfolderTypeId,
        });
      });
      subNotes.sort((a, b) => {
        if (!a.type_id || a.type_id < b.type_id) return -1;
        if (a.type_id > b.type_id) return 1;
        if (a.type_id === b.type_id) {
          if (a.type_id_no < b.type_id_no) return -1;
          if (a.type_id_no > b.type_id_no) return 1;
        }
        return 0;
      });
      return subNotes;
    },
  },
  methods: {
    showSubfolderNotes(subfolderId) {
      this.visibleSubfolderNotes = null;
      this.$nextTick(() => { this.visibleSubfolderNotes = this.subfolderNotes.find(obj => obj.id === subfolderId); });
    },
    resetSubf() {
      if (this.visibleSubfolderNotes) {
        this.showSubfolderNotes(this.visibleSubfolderNotes.id);
      }
    },
    addNote(subfolderToAdd) {
      this.subfolderToAdd = subfolderToAdd;
      this.showNoteAdd = true;
    },
    closeAddNote() {
      this.showNoteAdd = false;
      this.subfolderToAdd = {};
    },
    noteAdded(newNote) {
      this.$emit('added', newNote);
      this.resetSubf();
    },
    noteChanged(note) {
      this.$emit('changed', note);
      this.resetSubf();
    },
    subfAttrChanged(newVal) {
      this.$emit('subfAttrChanged', newVal);
      this.resetSubf();
    },
    noteDeleted(id) {
      this.$emit('deleted', id);
      this.resetSubf();
    },
    noteDeletedMany(ids) {
      this.$emit('deletedMany', ids);
      this.resetSubf();
    },
    downloadZip() {
      if (this.notes.length === 0) {
        return false;
      }
      const form = {
        noteable_type: this.noteableType,
        noteable_id: this.noteableId,
        category_id: this.categoryId,
        ids: [],
      };
      this.zipInfo = true;
      this.zipUrl = '';
      NoteService.downloadNotes(form)
        .then(response => {
          this.zipUrl = response.data.url;
          this.zipReady = true;
        })
        .catch(error => {
          this.resolveError(error);
        });
      return true;
    },
    closeOrderZipInfo() {
      this.zipInfo = false;
      this.zipUrl = '';
      this.zipReady = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .card {
    @apply my-2;
  }
  .card-header {
     @apply flex;
     @apply justify-between;
     @apply px-3;
     @apply py-1;
     @apply border;
     @apply border-b-0;
     @apply border-gray-400;
     @apply bg-gray-100;
     @apply rounded-t-sm;
     &.dark {
       @apply bg-gray-900;
       @apply text-gray-200;
       @apply border-gray-700;
     }
  }
  .card-header-title {
    @apply font-semibold;
  }
  .card-content {
    @apply px-3;
    @apply pt-1;
    @apply pb-3;
    @apply border;
    @apply border-gray-400;
    &.dark {
      @apply bg-gray-800;
      @apply text-gray-200;
      @apply border-gray-700;
    }
  }
  .card-data-section {
    @apply flex;
    @apply flex-wrap;
    @apply -mx-3;
    @apply -my-2;
  }
</style>
