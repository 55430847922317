<template>
  <AlertBig>
    <template #title>
      {{ message }}
    </template>
    <template v-if="message === ''">
      <font-awesome-icon icon="spinner" size="4x" spin />
    </template>
    <template v-if="message !==''" #button>
      <router-link  class="btn btn-primary" :to="{ name: 'login' }">Zaloguj ponownie</router-link>
    </template>
  </AlertBig>
</template>

<script>
import AlertBig from '../components/AlertBig.vue';

export default {
  name: 'LoggedOut',
  components: { AlertBig },
  data() {
    return {
      message: '',
    };
  },
  mounted() {
    this.$store.dispatch('logout')
      .then(() => { this.message = 'Poprawnie wylogowano z aplikacji'; })
      .catch(() => { this.message = 'Niepoprawnie wylogowano z aplikacji (błąd odpowiedzi serwera'; });
  },
};
</script>
