<template>
  <Transition name="fade">
    <div
      v-show="show"
      class="relative pl-3 pr-8 py-2 font-semibold rounded mb-2 opacity-75 shadow-2xl"
      :class="{'bg-red-400': variant === 'danger', 'bg-green-400': variant === 'success', 'bg-yellow-400': variant === 'warning'}"
    >
      <font-awesome-icon @click="close()" class="absolute right-0 mx-2 my-1 text-gray-300 cursor-pointer" icon="times" />
      {{ notification.message }}
    </div>
  </Transition>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timeout: null,
      tFade: null,
      show: false,
    };
  },
  mounted() {
    this.show = true;
    this.timeout = setTimeout(() => this.close(), 5000);
  },
  computed: {
    variant() {
      return this.notification.type === 'error' ? 'danger' : this.notification.type;
    },
  },
  methods: {
    ...mapActions('notifications', ['remove']),
    close() {
      this.show = false;
      setTimeout(() => this.remove(this.notification), 800);
    },
  },
};
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.8s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
