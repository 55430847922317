<template>
  <div class="flex p-0 bg-gray-100">
    <div class="hidden lg:flex flex-none items-center justify-end flex-1 md:w-1/2 xl:w-2/5 bg-cover bg-center -m-6" :style="`background-image: url(\'${require('@/assets/keyboard-3085978_1280.jpg')}\')`">
      <div class="bg-white shadow-lg md:w-full xl:w-4/5 py-20 px-20">
        <img :src="require('@/assets/logo.png')" alt="Logo Estimates">
        <p class="text-justify mt-8"><span class="mx-3"></span><span v-html="welcome.content"></span></p>
        <p class="mt-2 font-semibold"><em>{{ welcome.footer }}</em></p>
      </div>
    </div>
    <div class="flex items-center justify-start flex-1 -my-6 -mx-6 md:mx-0 lg:-mr-6">
      <div class="relative w-full xl:w-3/5 bg-gray-200 shadow-lg py-10 px-10 md:px-32 h-screen md:h-auto">
        <div>
          <div class="lg:hidden w-100 text-center">
            <img :src="require('@/assets/logo.png')" alt="Logo Estimates" class="inline-block">
          </div>

          <h1 class="font-bold text-3xl">{{ formTitle }}</h1>

          <slot name="form" />

          <p
            v-if="afterMessage !== ''"
            class="absolute -mt-4"
            :class="{'text-red-500': afterMessageType === 'danger', 'text-green-500': afterMessageType === 'success'}"
          >
            <em>*{{ afterMessage }}</em>
          </p>
          <div class="lg:hidden">
            <p class="text-justify mt-8"><span class="mx-3"></span><span v-html="welcome.content"></span></p>
            <p class="mt-2 font-semibold"><em>{{ welcome.footer }}</em></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotLogged',
  props: {
    formTitle: {
      type: String,
      required: true,
    },
    afterMessage: {
      type: String,
      default: '',
    },
    afterMessageType: {
      type: String,
      default: 'danger',
    },
    welcome: {
      type: Object,
      default: () => ({ content: '', footer: '' }),
    },
  },
};
</script>
<style scoped>
  input {
    @apply bg-white;
    @apply border;
    @apply rounded-sm;
    @apply py-2;
    @apply px-4;
    @apply block;
    @apply w-full;
    @apply appearance-none;
    @apply leading-normal;
    @apply text-2xl;
    @apply my-2;
  }
  input:focus {
    @apply outline-none;
    @apply border-blue-300;
  }
</style>
