/* eslint-disable no-shadow */
/**
 * states about lists needs updates
 */
const state = {
  userList: false,
  employeeList: false,
  contractorList: false,
};

const mutations = {
  SET_LIST_NEEDS_UPDATE_STATE(state, payload) {
    state[payload.list] = payload.value;
  },
};

const actions = {
  userListNeedsUpdate({ commit }) {
    commit('SET_LIST_NEEDS_UPDATE_STATE', { list: 'userList', value: true });
  },
  userListUpdated({ commit }) {
    commit('SET_LIST_NEEDS_UPDATE_STATE', { list: 'userList', value: false });
  },
  employeeListNeedsUpdate({ commit }) {
    commit('SET_LIST_NEEDS_UPDATE_STATE', { list: 'employeeList', value: true });
  },
  employeeListUpdated({ commit }) {
    commit('SET_LIST_NEEDS_UPDATE_STATE', { list: 'employeeList', value: false });
  },
  contractorListNeedsUpdate({ commit }) {
    commit('SET_LIST_NEEDS_UPDATE_STATE', { list: 'contractorList', value: true });
  },
  contractorListUpdated({ commit }) {
    commit('SET_LIST_NEEDS_UPDATE_STATE', { list: 'contractorList', value: false });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
