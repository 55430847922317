import '@babel/polyfill';
import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner, faUserPlus, faUserCheck, faUserTimes, faUserLock, faUserTie, faPhone, faAt, faTimes, faQuestionCircle, faList, faThList, faListOl, faBars,
  faExternalLinkAlt, faEdit, faKey, faPlus, faBuilding, faTrashAlt, faAngleLeft, faCaretRight, faLaptopCode, faFileContract, faDownload, faFileAlt, faFilePdf,
  faHistory, faUserSlash, faSave, faUndo, faEye, faEyeSlash, faCheck, faHourglassStart, faExclamationTriangle, faFileInvoice, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { faSquare, faCheckSquare, faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import App from './App.vue';
import router from './router';
import store from './store';
import './filters';
import BaseModal from './components/BaseModal.vue';

library.add(faSpinner, faUserPlus, faUserCheck, faUserTimes, faUserLock, faUserTie, faPhone, faAt, faTimes, faQuestionCircle, faList, faThList, faListOl, faBars,
  faExternalLinkAlt, faEdit, faKey, faPlus, faBuilding, faTrashAlt, faAngleLeft, faCaretRight, faLaptopCode, faFileContract, faDownload, faFileAlt, faFilePdf,
  faHistory, faUserSlash, faSave, faUndo, faEye, faEyeSlash, faCheck, faHourglassStart, faExclamationTriangle, faFileInvoice, faMapMarkedAlt, faSquare, faCheckSquare, faFileExcel);

Vue.directive('focus', {
  inserted(el, binding) {
    if (binding.value !== false) {
      el.focus();
    }
  },
});
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('BaseModal', BaseModal);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  created() {
    const userString = localStorage.getItem('user');
    if (userString) {
      const userData = JSON.parse(userString);
      this.$store.commit('SET_USER_DATA', userData);
    }
  },
  render: h => h(App),
}).$mount('#app');
