import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

apiClient.interceptors.request.use(config => {
  if (apiClient.defaults.headers.common.Authorization !== axios.defaults.headers.common.Authorization) {
    apiClient.defaults.headers.common.Authorization = axios.defaults.headers.common.Authorization;
  }
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = axios.defaults.headers.common.Authorization;
  return config;
}, error => Promise.reject(error));

export default {
  getUsers(query) {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/users', {
        params: query,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  getUser(id) {
    const result = new Promise((resolve, reject) => {
      apiClient.get(`/users/${id}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  updateUser(id, body) {
    const result = new Promise((resolve, reject) => {
      apiClient.patch(`/users/${id}/update`, body)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  addUser(body) {
    const result = new Promise((resolve, reject) => {
      apiClient.post('/users', body)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
};
