import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import Dashboard from '../views/Dashboard.vue';
import Profile from '../views/Profile.vue';
import NotFound from '../views/NotFound.vue';
import Login from '../views/Login.vue';
import LoggedOut from '../views/LoggedOut.vue';
import ApiIssue from '../views/ApiIssue.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: { name: 'orders' },
  },
  {
    path: '/profil-uzytkownika',
    name: 'profile',
    component: Profile,
    meta: { niceName: 'Profil uzytkownika' },
  },
  {
    path: '/kalendarz',
    name: 'dashboard',
    component: Dashboard,
    meta: { niceName: 'Kalendarz' },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { forNotLogged: true },
  },
  {
    path: '/logout',
    name: 'logout',
    component: LoggedOut,
    meta: { forNotLogged: true },
  },
  {
    path: '/uzytkownicy',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue'),
    meta: { niceName: 'Użytkownicy' },
    children: [
      {
        path: ':id',
        name: 'user',
        component: () => import(/* webpackChunkName: "user" */ '../views/UserItem.vue'),
      },
    ],
  },
  {
    path: '/pracownicy',
    name: 'employees',
    component: () => import(/* webpackChunkName: "employees" */ '../views/Employees.vue'),
    meta: { niceName: 'Pracownicy' },
    children: [
      {
        path: ':id',
        name: 'employee',
        component: () => import(/* webpackChunkName: "employee" */ '../views/EmployeeItem.vue'),
      },
    ],
  },
  {
    path: '/zleceniodawcy',
    name: 'contractors',
    component: () => import(/* webpackChunkName: "contractors" */ '../views/Contractors.vue'),
    meta: { niceName: 'Zleceniodawcy' },
    children: [
      {
        path: ':id',
        name: 'contractor',
        component: () => import(/* webpackChunkName: "contractor" */ '../views/ContractorItem.vue'),
      },
    ],
  },
  {
    path: '/szkody/:id',
    name: 'order',
    component: () => import(/* webpackChunkName: "order" */ '../views/OrderItem.vue'),
    meta: { niceName: 'Karta szkody' },
  },
  {
    path: '/szkody',
    name: 'orders',
    component: () => import(/* webpackChunkName: "orders" */ '../views/Orders.vue'),
    meta: { niceName: 'Szkody' },
  },
  {
    path: '/password-email',
    name: 'passwordEmail',
    component: () => import(/* webpackChunkName: "passemail" */ '../views/PasswordEmail.vue'),
    meta: { forNotLogged: true },
  },
  {
    path: '/password-reset/:token',
    name: 'PasswordReset',
    component: () => import(/* webpackChunkName: "passreset" */ '../views/PasswordReset.vue'),
    meta: { forNotLogged: true },
  },
  {
    path: '/rozliczenia',
    name: 'settlements',
    component: () => import(/* webpackChunkName: "settlements" */ '../views/Settlements.vue'),
    meta: { niceName: 'Rozliczenia' },
    children: [
      {
        path: 'rozliczenia-pracownikow',
        name: 'salaries',
        component: () => import(/* webpackChunkName: "salaries" */ '../views/Salaries.vue'),
        meta: { niceName: 'Rozliczenia pracowników' },
      },
      {
        path: 'raport-zleceniodawcy',
        name: 'orderIncomeReport',
        component: () => import(/* webpackChunkName: "orderIncomeReport" */ '../views/OrderIncomeReport.vue'),
        meta: { niceName: 'Finanse - szkody wg. zleceniodawców' },
      },
    ],
  },
  {
    path: '/rzeczoznawca-rozliczenia',
    name: 'expertSalaries',
    component: () => import(/* webpackChunkName: "expertSalaries" */ '../views/ExpertSalaries.vue'),
    meta: { niceName: 'Rzeczoznawca - rozliczenia' },
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
    meta: { forNotLogged: true },
    props: true,
  },
  {
    path: '/500',
    name: '500',
    component: ApiIssue,
    meta: { forNotLogged: true },
  },
  {
    path: '*',
    redirect: { name: 404 },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user');
  if (!to.matched.some(record => record.meta.forNotLogged) && !loggedIn) {
    store.dispatch('storeBackToURL', to.fullPath);
    next({ name: 'login' });
  } else if (to.name === 'login' && loggedIn) {
    next({ name: 'orders' });
  }
  next();
});

export default router;
