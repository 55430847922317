<template>
  <AlertBig>
    <template #title>
      Ups! Coś poszło nie tak!
    </template>
    <template #subtitle>
      Serwer nie odpowiada lub przekroczono czas odpowiedzi serwera podczas próby pobrania zbyt dużej paczki.
    </template>
    <template #default>
      Spróbuj ponownie za jakiś czas lub, w przypadku dużej ilości załączników, pobierz dokumentację w częściach.
    </template>
    <template #button>
      <button class="py-3 px-5 bg-blue-500 hover:bg-blue-400 text-gray-300 rounded-lg" @click="$router.go(-1)">Powrót</button>
    </template>
  </AlertBig>
</template>

<script>
import AlertBig from '../components/AlertBig.vue';

export default {
  name: 'ApiIssue',
  components: {
    AlertBig,
  },
};
</script>
