<template>
  <div class="flex justify-center items-center align-middle">
    <div class="max-w-xl p-20 border border-gray-200 bg-gray-100 shadow rounded-lg border">
      <h1 class="text-xl font-bold">
        <slot name="title"></slot>
      </h1>
      <h4 class="font-bold">
        <slot name="subtitle"></slot>
      </h4>
      <p class="mb-6"><slot></slot></p>
      <slot name="button"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlertBig',
};
</script>
