import axios from 'axios';

/* eslint-disable no-shadow */
const state = {
  noteSubfolderTypeList: [],
};

const mutations = {
  SET_NOTE_SUBFOLDER_TYPE_LIST(state, noteSubfolderTypeList) {
    state.noteSubfolderTypeList = noteSubfolderTypeList;
  },
};

const actions = {
  getNoteSubfolderTypeList({ commit }) {
    axios.get(`${process.env.VUE_APP_API_URL}/note-subfolder-type-list`)
      .then(response => commit('SET_NOTE_SUBFOLDER_TYPE_LIST', response.data))
      .catch(error => console.log(error));
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
