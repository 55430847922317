<template>
  <div>
    <div class="hidden md:block text-center mt-2">
      <img class="inline-block rounded shadow-xl h-6 opacity-75" :src="require('@/assets/logo.png')" alt="Logo Estimates">
    </div>
    <ul class="list-reset flex flex-row md:flex-col mt-4 text-center md:text-left md:border-t md:border-blue-800">
      <li v-for="(item, index) in verifiedItems" :key="index" class="flex-1 md:block mx-3 md:mx-0" :class="{ 'hidden': !item.mobile }">
        <router-link
          class="block py-1 md:py-3 md:pl-3 align-middle no-underline border-b-2 md:border-b border-gray-800 md:border-blue-800
                  md:hover:border-blue-800 md:hover:bg-blue-700 hover:border-yellow-500
                  text-gray-600 md:text-gray-400 hover:text-white"
          :to="{ name: item.routeName }" active-class="active" :exact="item.exact"
        >
          <span class="block md:inline-block pb-1 md:pb-0 text-xs md:text-base xl:text-sm ">
            {{ item.label }}
          </span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'MainSideBar',
  data() {
    return {
      items: [
        // {
        //   routeName: 'dashboard',
        //   label: 'Kalendarz',
        //   mobile: true,
        //   exact: true,
        //   allow: 'dashboard',
        // },
        {
          routeName: 'orders',
          label: 'Szkody',
          mobile: true,
          exact: false,
          allow: 'order_list',
        },
        {
          routeName: 'contractors',
          label: 'Zleceniodawcy',
          mobile: true,
          exact: false,
          allow: 'contractor_list',
        },
        {
          routeName: 'employees',
          label: 'Pracownicy',
          mobile: true,
          exact: false,
          allow: 'employee_list',
        },
        {
          routeName: 'users',
          label: 'Użytkownicy',
          mobile: true,
          exact: false,
          allow: 'user_list',
        },
        {
          routeName: 'settlements',
          label: 'Rozliczenia',
          mobile: true,
          exact: false,
          allow: 'admin_salaries_menu',
        },
        {
          routeName: 'expertSalaries',
          label: 'Rozliczenia',
          mobile: true,
          exact: true,
          allow: 'expert_salaries_menu',
        },
        {
          routeName: 'logout',
          label: 'Wyloguj',
          mobile: false,
          exact: false,
          allow: 'all',
        },
      ],
    };
  },
  computed: {
    userAbilities() {
      return this.$store.state.user.abilities;
    },
    verifiedItems() {
      return this.items.filter(item => this.userAbilities.includes(item.allow) || item.allow === 'all');
    },
  },
};
</script>

<style scoped>
 .active {
   @apply border-blue-800;
   @apply bg-blue-700;
   @apply text-white;
 }
</style>
