<template>
  <BaseModal
    :activeModal="show"
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    okLabel="zapisz"
    @ok="save"
    :close="false"
    :bgClose="false"
    size="md"
  >
    <template #header>
      Zmiana hasła dla: {{ user.name }}
    </template>
    <template #default>
      <div class="mb-4">
        <label>nowe hasło</label>
        <input v-focus @keyup.enter="save" @keyup.esc="show = false" :class="{ 'border-red-300': errors.password !== undefined }" v-model="form.password" type="password">
      </div>
      <div class="mb-4">
        <label>potwierdź hasło</label>
        <input @keyup.enter="save" @keyup.esc="show = false" :class="{ 'border-red-300': errors.password !== undefined }" v-model="form.password_confirmation" type="password">
      </div>
      <p v-if="errors.password !== undefined" class="text-red-500 text-sm">{{ errors.password }}</p>
    </template>
  </BaseModal>
</template>

<script>
import UserService from '../services/UserService';
import { errorHandler } from '../mixins/errorHandler';

export default {
  name: 'UserChagePass',
  mixins: [errorHandler],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        password: '',
        password_confirmation: '',
      },
      errors: {},
      show: false,
    };
  },
  mounted() {
    this.show = true;
  },
  methods: {
    save() {
      UserService.updateUser(this.user.id, this.form)
        .then(() => {
          this.$store.dispatch('notifications/add', {
            type: 'success',
            message: 'Zmiany zostały zapisane...',
          });
          this.$emit('changed');
          this.show = false;
        })
        .catch(error => {
          this.errors = this.resolveError(error);
        });
    },
  },
};
</script>
