<template>
  <div>
    <div v-if="isBusy">
      <div class="card">
        <div class="card-header">
          <span class="card-header-title">Ładuję dane...</span>
        </div>
        <div class="card-content">
          <div v-if="isBusy" class="w-full p-4 h-full text-center">
            <font-awesome-icon class="text-6xl" icon="spinner" pulse/>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="main-container">
      <div class="container-column w-full md:w-1/2"> <!-- column left -->
        <div class="card">
          <div class="card-header">
            <span class="card-header-title">Dane użytkownika</span>
          </div>
          <div class="card-content">
            <div class="card-data-section"> <!-- section -->
              <div class="column w-full">
                <h1 class="text-xl font-semibold">Dane logowania</h1>
                <hr>
                <table class="mt-3">
                  <tr>
                    <td class="font-semibold text-gray-500">login:</td>
                    <td>{{ user.name }}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold text-gray-500">email:</td>
                    <td>{{ user.email }}</td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-xs text-gray-500">email do zmiany hasła, powiadomień systemowych oraz jako alternatywny login</td>
                  </tr>
                </table>
                <button @click="showPassChange = true" class="mt-1 px-3 py-1 text-red-500 border border-red-500 hover:text-red-100 hover:bg-red-500 rounded font-semibold tracking-wide focus:outline-none">
                  zmień hasło
                </button>
              </div> <!-- column -->
            </div> <!-- section -->

            <div class="card-data-section mt-3">
              <div class="column w-full">
                <h1 class="text-xl font-semibold">Dane personalne</h1>
                <hr>
                <div class="mt-3">
                  <p>{{ user.person.first_name }} {{ user.person.middle_name }} {{ user.person.surname }}</p>
                  <p v-show="user.person.pesel_passport"><em class="text-gray-500">pesel:</em> {{user.person.pesel_passport}}</p>
                  <p>
                    <span v-if="user.person.phone"><font-awesome-icon icon="phone" />&nbsp;
                      <span v-if="Number(user.person.regional_phone) === 1">{{ user.person.phone | phoneNo}}</span>
                      <span v-else>{{ user.person.phone }} <span class="text-xs text-gray-500"><em>zagraniczny</em></span></span>
                      <br>
                    </span>
                    <span v-if="user.person.email && user.person.email !== user.email"><font-awesome-icon icon="at" /> {{ user.person.email }}<br></span>
                    <span v-if="user.person.address1">
                      {{ user.person.address1.street }} {{ user.person.address1.house_apt_no }}<br>
                      {{ user.person.address1.postal_code }} {{ user.person.address1.city }}<br>
                      <span class="text-sm text-gray-500"><em>region:</em></span> {{ user.person.address1.region ? user.person.address1.region.name : '' }}
                    </span>
                  </p>
                  <p>
                    <span class="font-semibold text-gray-600">konto bank: </span>
                    <span v-if="user.person.bank_acct_no">
                      {{ user.person.bank }} {{ user.person.bank_acct_no | bankAcc }}
                    </span>
                    <span v-else class="text-sm text-gray-500">nie podano</span>
                  </p>
                </div>
              </div> <!-- column -->
            </div> <!-- section -->
          </div> <!-- card-content -->
        </div> <!-- card -->
      </div> <!-- column left -->
      <div class="container-column w-full md:w-1/2"> <!-- column right -->
        <div class="card">
          <div class="card-header">
            <span class="card-header-title">Role w aplikacji</span>
          </div>
          <div class="card-content">
            <div class="card-data-section"> <!-- section -->
              <div class="column w-full">
                <div v-if="user.person.employee" class="flex">
                  <span class="font-semibold text-gray-600 mr-2">Pracownik (rola):</span>
                  <div v-for="(empRole, index) in user.employee_roles" :key="index">
                    <span v-if="index > 0">, </span>{{ empRole }}
                  </div>
                </div>
                <div v-if="user.contractors.length > 0" class="flex">
                  <span class="font-semibold text-gray-600 mr-3">
                    Osoba kontaktowa w:
                  </span>
                  <div v-for="(contractor, index) in user.contractors" :key="contractor.id">
                    <span v-if="index > 0">, </span><span class="text-sm">{{ contractor.company.shortname }}</span>
                  </div>
                </div>
              </div> <!-- column -->
            </div> <!-- section -->

            <div v-if="employee.id" class="card-data-section mt-3">
              <div class="column w-full">
                <h1 class="text-xl font-semibold">Karta pracownika</h1>
                <hr>
                <table class="mt-0">
                  <tr>
                    <td class="font-semibold text-gray-500">role w aplikacji:</td>
                    <td><span v-for="role in employee.roles" :key="role.id" class="px-1 bg-teal-200 rounded mr-1">{{ role.label }}</span></td>
                  </tr>
                  <tr>
                    <td class="font-semibold text-gray-500">zarządza pracownikami: </td>
                    <td>{{ Number(employee.is_manager) === 1 ? 'Tak' : 'Nie' }}</td>
                  </tr>
                  <tr v-if="employee.manager">
                    <td class="font-semibold text-gray-500">manager:</td>
                    <td>{{ `${employee.manager.person.first_name} ${employee.manager.person.surname}` }}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold text-gray-500">forma zatrudn.:</td>
                    <td>{{ employee.employmentForm.name }}</td>
                  </tr>
                </table>

                <div class="relative w-full mb-3"> <!-- company block -->
                  <h1 class="font-semibold leading-7 text-gray-600">Dane do faktury</h1>
                  <hr>
                  <div v-if="employee.company" class="mt-0">
                    <p class="font-semibold">{{ employee.company.name }}</p>
                    <p><span class="text-gray-500">NIP:</span> {{employee.company.nip}}</p>
                    <p>
                      <span v-if="employee.company.address1">
                        {{ employee.company.address1.street }} {{ employee.company.address1.house_apt_no }};
                        {{ employee.company.address1.postal_code }} {{ employee.company.address1.city }}<br>
                      </span>
                    </p>
                    <p>
                      <span class="font-semibold text-gray-600">konto bank: </span>
                      <span v-if="employee.company.bank_acct_no">
                        {{ employee.company.bank }} {{ employee.company.bank_acct_no | bankAcc }}
                      </span>
                      <span v-else class="text-sm text-gray-500">nie podano</span>
                    </p>
                  </div>
                  <div v-else class="mt-0">
                    <span class="text-gray-500 text-sm">nie podano</span>
                  </div>
                </div> <!-- end of company block -->

                <Notes
                  title="Notatki i załączniki"
                  :noteableId="employee.id"
                  noteableType="App\Employee"
                  categoryId="1"
                  :notes="employee.notes"
                  :token="employee.images_token"
                  @added="noteAdded"
                  @changed="noteChanged"
                  @deleted="noteDeleted"
                  @deletedMany="noteDeletedMany"
                />
              </div> <!-- column -->
            </div> <!-- section -->
          </div> <!-- card-content -->
        </div> <!-- card -->
      </div> <!-- column right -->
    </div> <!-- container -->
    <UserChangePass
      v-if="showPassChange"
      @close="showPassChange = false"
      :user="user"
    />

  </div>
</template>

<script>
import UserService from '../services/UserService';
import EmployeeService from '../services/EmployeeService';
import { errorHandler } from '../mixins/errorHandler';
import Notes from '../components/Notes.vue';
import UserChangePass from '../components/UserChangePass.vue';

export default {
  name: 'Profile',
  components: {
    Notes,
    UserChangePass,
  },
  mixins: [errorHandler],
  data() {
    return {
      user: {},
      employee: {},
      showPassChange: false,
      isBusy: true,
      isBusyEmployee: true,
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    getUser() {
      this.isBusy = true;
      UserService.getUser(this.$store.state.user.id)
        .then(response => {
          this.user = response.data.result;
          if (this.user.person.employee) {
            this.getEmployee(this.user.person.employee.id);
          }
          this.isBusy = false;
        })
        .catch(error => {
          this.resolveError(error);
          this.isBusy = false;
        });
    },
    getEmployee(id) {
      this.isBusyEmployee = true;
      EmployeeService.getEmployee(id)
        .then(response => {
          this.employee = response.data.result;
          this.isBusyEmployee = false;
        })
        .catch(error => {
          this.resolveError(error);
          this.isBusyEmployee = false;
        });
    },
    noteAdded(note) {
      this.employee.notes.unshift(note);
    },
    noteChanged(note) {
      const oldNote = this.employee.notes.find(obj => Number(obj.id) === Number(note.id));
      Object.assign(oldNote, note);
    },
    noteDeleted(id) {
      this.employee.notes = this.employee.notes.filter(obj => Number(obj.id) !== Number(id));
    },
    noteDeletedMany(ids) {
      const idsDeleted = ids.map(el => Number(el));
      this.employee.notes = this.employee.notes.filter(obj => !idsDeleted.includes(obj.id));
    },
  },
};
</script>

<style lang="scss" scoped>
  .main-container {
    @apply flex;
    @apply flex-wrap;
    @apply -mx-3;
  }
  .container-column {
    @apply px-3;
  }
  .card {
    @apply my-2;
  }
  .card-header {
     @apply flex;
     @apply justify-between;
     @apply px-3;
     @apply py-1;
     @apply border;
     @apply border-b-0;
     @apply border-gray-400;
     @apply bg-gray-100;
     @apply rounded-t-sm;
     &.dark {
       @apply bg-gray-900;
       @apply text-gray-200;
       @apply border-gray-700;
     }
  }
  .card-header-title {
    @apply font-semibold;
  }
  .card-content {
    @apply px-3;
    @apply pt-1;
    @apply pb-3;
    @apply border;
    @apply border-gray-400;
    &.dark {
      @apply bg-gray-800;
      @apply text-gray-200;
      @apply border-gray-700;
    }
  }
  .card-data-section {
    @apply flex;
    @apply flex-wrap;
    @apply -mx-3;
    .column {
      @apply px-3;
    }
    .title {
      @apply block;
      @apply font-semibold;
    }
    .title-inline {
      @apply inline-block;
      @apply font-semibold;
    }
    .field-data {
      @apply inline-block;
      @apply w-full;
      @apply px-2;
      @apply mb-1;
      @apply border;
      @apply bg-gray-200;
      @apply rounded-sm;
      &.dark {
        @apply text-gray-700;
      }
    }
    .field-data-inline {
      @apply inline-block;
      @apply px-2;
      @apply mb-1;
      @apply border;
      @apply bg-gray-200;
      @apply rounded-sm;
      &.dark {
        @apply bg-gray-700;
        @apply border-gray-800;
        @apply text-gray-200;
      }
      &.text-muted-dark {
        @apply text-gray-500;
      }
    }
  }
  .section-title {
    @apply mt-1;
    @apply text-gray-600;
    @apply text-sm;
    @apply font-semibold;
    @apply uppercase;
  }
  .text-muted {
    @apply text-gray-500;
  }
</style>
