/* eslint-disable no-shadow */
const state = {
  active: false,
  message: '',
  callback: null,
};

const mutations = {
  OPEN_DIALOG(state, payload) {
    state.active = true;
    state.message = payload.message;
    state.callback = payload.callback;
  },
  CLOSE_DIALOG(state) {
    state.active = false;
    state.message = '';
    state.callback = null;
  },
};

const actions = {
  openDialog({ commit, dispatch }, payload) {
    if (typeof payload.callback !== 'function') {
      const notification = { type: 'error', message: 'Nieprawidłowe przekazanie funkcji do metody confirm! Powiadom administratora!' };
      dispatch('notifications/add', notification, { root: true });
      return;
    }
    commit('OPEN_DIALOG', payload);
  },
  closeDialog({ commit }) {
    commit('CLOSE_DIALOG');
  },
};

const getters = {
  active() {
    return state.active;
  },
  message() {
    return state.message;
  },
  callback() {
    return state.callback;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
