<template>
  <BaseModal
    :activeModal="show"
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    okLabel="zapisz"
    @ok="save"
    :close="false"
    :bgClose="false"
    size="md"
  >
    <template #header>
      Edycja cechy obiektu
    </template>
    <template #default>
      <div class="mb-4">
        <label>Cecha obiektu</label>
        <input v-focus :class="{ 'border-red-300': errors.title !== undefined }" v-model="form.attribute" type="text">
        <p v-if="errors.attribute !== undefined" class="text-red-500 text-sm">{{ errors.attribute }}</p>
      </div>

      <!-- Spinner Loading -->
      <div v-if="isBusy" class="absolute top-0 left-0 w-full h-full">
        <div class="w-full h-full bg-gray-400 opacity-75"></div>
        <div class="absolute top-0 left-0 flex w-full h-full items-center justify-center">
          <font-awesome-icon class="mr-3" icon="spinner" size="2x" spin />
          <div class="font-bold">
            <p>Zapisuję...</p>
          </div>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import NoteService from '../services/NoteService';
import { errorHandler } from '../mixins/errorHandler';

export default {
  name: 'NoteSubfolderAttrEdit',
  mixins: [errorHandler],
  props: {
    attribute: {
      type: String,
      required: true,
    },
    noteSubfolderId: {
      required: true,
    },
  },
  data() {
    return {
      form: {
        attribute: '',
      },
      errors: {},
      show: false,
      isBusy: false,
    };
  },
  mounted() {
    this.show = true;
    this.form.attribute = this.attribute;
  },
  methods: {
    save() {
      this.isBusy = true;
      NoteService.updateNoteSubfolderAttribute(this.noteSubfolderId, this.form)
        .then(response => {
          this.$emit('changed', response.data);
          this.isBusy = false;
          this.closeModal();
        })
        .catch(error => {
          this.errors = this.resolveError(error);
          this.isBusy = false;
        });
    },
    closeModal() {
      this.show = false;
      this.$emit('close');
    },
  },
};
</script>
